import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../userContext';
import { identify } from '../components/util/tracker';
import SpotifyAccess from '../common/spotify';

type CallbackState = {
  redirect: boolean,
  authenticated: boolean,
  accessToken: string
}

export class CallbackSpotify extends Component<{}, CallbackState> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: {}) {
    super(props);
    this.state = { redirect: false, authenticated: false, accessToken: "" };
  }

  componentDidMount() {
    const accessToken = this.setAccessToken();

    if (accessToken) {
      const spotifyAccess = new SpotifyAccess(accessToken);
      // TODO: this is repeated in userContext.tsx... consolidate
      spotifyAccess.getMe().then(profile => {
        this.context.setUserData({
          spotify: {
            authenticated: true,
            id: profile.id,
            accessToken: accessToken,
            profile: profile
          }
        });
        identify(profile.id);
        this.setState({ redirect: true });
      });
    } else {
      alert("something went wrong with auth");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>Processing authentication...</div>
      )
    }
  }

  setAccessToken(): string | null {  // Get and store access_token in local storage
    let accessToken = this.getParameterByName('access_token');
    if (accessToken) {
      localStorage.setItem('SPOTIFY_ACCESS_TOKEN', accessToken);
      return accessToken;
    } else {
      alert("da fu?");
      return null;
    }
  }

  getParameterByName(parameterName: string) {
    let match = RegExp('[#&]' + parameterName + '=([^&]*)').exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
}