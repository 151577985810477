import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import SpotifyAccess from '../common/spotify';
import { UserContext } from '../userContext';
import { Artist } from '../components/Artist';
import { AlbumSummary } from '../components/AlbumSummary';

/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />

const AlbumList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div { 
    margin: 3px;
  }
`;

export const ArtistSampler: React.FC = () => {
  const { userData } = useContext(UserContext);
  const accessToken = userData.spotify.accessToken;
  const userId = userData.spotify.id;
  // const spotifyApi = new SpotifyAccess(userData.spotify.accessToken);

  const [searchString, setSearchString] = useState("rush");
  const [trackLimit, setTrackLimit] = useState(3);
  const [excludeLive, setExcludeLive] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [searchedArtists, setSearchedArtists] = useState<SpotifyApi.ArtistObjectFull[]>([]);
  const [selectedArtist, setSelectedArtist] = useState<SpotifyApi.ArtistObjectFull>();
  const [artistAlbums, setArtistAlbums] = useState<SpotifyApi.AlbumObjectFull[]>([]);
  const [trackAudioFeatures, setTrackAudioFeatures] = useState<SpotifyApi.AudioFeaturesObject[]>([]);
  const [liveAlbumIds, setLiveAlbumIds] = useState<string[]>([]);
  const [albumTracks, setAlbumTracks] = useState<SpotifyApi.TrackObjectFull[]>([]);
  const [sortPopular, setSortPopular] = useState(true);
  const [selectedAlbums, setSelectedAlbums] = useState({});

  const albumsFiltered = () => artistAlbums.filter(album => excludeLive ? !liveAlbumIds.includes(album.id) : true);
  const tracksFiltered = (albumId?: string) => {
    const albums = albumsFiltered().filter(album => albumId ? album.id === albumId : true);
    const tracks = albums.map(album => {
      let tracks = albumTracks.filter(track => track.album.id === album.id);
      if (sortPopular) tracks.sort((a, b) => b.popularity - a.popularity);
      return tracks.slice(0, trackLimit);
    }).reduce((p, c) => p.concat(c));
    return tracks;
  };

  const createPlaylist = () => {
    const tracks = tracksFiltered();
    SpotifyAccess.createPlaylist(accessToken, userId, playlistName)
      .then((playlist) => {
        if (playlist) {
          return SpotifyAccess.addTracksToPlaylist(accessToken, userId, playlist.id, tracks.map(t => t.uri));
        } else {
          throw new Error('Could not add tracks to created playlist.');
        }
      }, (reason: any) => {
        console.log(reason);
      })
      .then(() => {
        console.log("Successfully created playlist.");
      });;
  };

  useEffect(() => {
    async function searchArtists() {
      if (searchString.length >= 2) {
        const artists = await SpotifyAccess.searchArtists(accessToken, searchString);
        setSearchedArtists(artists);
      }
    }
    searchArtists();
  }, [searchString]);

  useEffect(() => {
    setArtistAlbums([]);
    setAlbumTracks([]);
    setTrackAudioFeatures([]);
    setExcludeLive(false);
    async function getArtistAlbums() {
      if (selectedArtist) {
        const artistAlbums = await SpotifyAccess.getArtistAlbums(accessToken, selectedArtist.id);
        const albumIds = artistAlbums.map(album => album.id);
        const albums = await SpotifyAccess.getAlbums(accessToken, albumIds);
        setArtistAlbums(albums);
        const trackIds = albums.map(album => album.tracks.items).reduce((p, c) => p.concat(c)).map(track => track.id);
        const tracks = await SpotifyAccess.getTracks(accessToken, trackIds);
        setAlbumTracks(tracks);
      }
    }
    getArtistAlbums();
  }, [selectedArtist]);

  useEffect(() => {
    async function getAudioFeatures() {
      const trackIds = artistAlbums.map(album => album.tracks.items).reduce((p, c) => p.concat(c)).map(track => track.id);
      if (artistAlbums && artistAlbums.length > 0) {
        const features = await SpotifyAccess.getAudioFeatures(accessToken, trackIds);
        setTrackAudioFeatures(features);
      }
    }
    if (excludeLive && (!trackAudioFeatures || trackAudioFeatures.length === 0)) {
      getAudioFeatures();
    }
  }, [excludeLive]);

  useEffect(() => {
    const liveAlbumIds = artistAlbums.filter(album => {
      const albumFeatures = trackAudioFeatures.filter(feature => album.tracks.items.map(i => i.id).includes(feature.id));
      const livenesses = albumFeatures.map(feature => feature.liveness);
      const albumAvgLiveness = livenesses.reduce((prev, curr) => prev + curr, 0) / livenesses.length;
      console.log(album.name + " - liveness: " + albumAvgLiveness, livenesses);
      return albumAvgLiveness >= .5;
    }).map(album => album.id);
    setLiveAlbumIds(liveAlbumIds);
  }, [trackAudioFeatures]);

  return (<div>
    {!selectedArtist ? <input value={searchString} onChange={(e) => setSearchString(e.target.value)} /> : ""}
    {
      selectedArtist && artistAlbums && artistAlbums.length > 0 ?
        <div>
          <div onClick={(e) => setSelectedArtist(undefined)}>&lt; back</div>
          <h3>{selectedArtist.name}</h3>
          <div>Include <input value={trackLimit} type="number" min={1} max={5} onChange={(e) => setTrackLimit(e.target.valueAsNumber)} /> songs per album</div>
          <div><input type="checkbox" checked={excludeLive} onChange={(e) => setExcludeLive(e.target.checked)} /> Exclude live albums</div>
          <div><input value={playlistName} placeholder="Playlist name" onChange={(e) => setPlaylistName(e.target.value)} /> <button onClick={createPlaylist}>Create playlist</button></div>
          <AlbumList>
            {
              albumsFiltered().map(album => <AlbumSummary key={album.id} album={album} tracks={tracksFiltered(album.id)} />)
            }
          </AlbumList>
        </div>
        : searchedArtists.map((artist) => <div key={artist.id} onClick={(e) => setSelectedArtist(artist)}><Artist artist={artist} /></div>)
    }
  </div>);
}