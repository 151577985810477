import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Login } from '../components/Login';
import { UserContext } from '../userContext';

export const Home: React.FC = () => {
  const { userData } = useContext(UserContext);
  return (
    <div className="hello container">
      <div>
        <Login></Login>
      </div>
      <h1>Hello {userData.spotify.profile?.display_name}</h1>
      <div className="row">
        <div className="col"></div>
      </div>
      <h5>simple smarts</h5>
      <div className="row">
        <div className="col-sm-2 col-12"><Link to="/tops">tops</Link></div>
        <div className="col-sm-10 col-12"><small>your top played tracks on spotify</small></div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-12"><Link to="/recents">recents</Link></div>
        <div className="col-sm-10 col-12"><small>your recently played tracks on spotify</small></div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-12"><Link to="/cloner">cloner</Link></div>
        <div className="col-sm-10 col-12"><small>make a copy of a playlist on spotify</small></div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-12"><Link to="/combiner">combiner</Link></div>
        <div className="col-sm-10 col-12"><small>combine two or more playlists on spotify</small></div>
      </div>
      <div>
        <h5>special smarts</h5>
      <div className="row">
      <div className="col-sm-2 col-12"><Link to="/artist-sampler">artist sampler</Link></div>
        <div className="col-sm-10 col-12"><small>sample the most popular tracks from each of an artist's albums</small></div>
      <div className="col-sm-2 col-12"><Link to="/emotives">emotives</Link></div>
        <div className="col-sm-10 col-12"><small>find the happiest, saddest, danciest, fiercest songs</small></div>
        </div>
        <h5>combo smarts</h5>
        <div>
          <img src="//icons.iconarchive.com/icons/danleech/simple/1024/lastfm-icon.png" style={{ width: 20, height: 20 }} alt="Last.fm" />
          &gt;
              <img src="//upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/768px-Spotify_logo_without_text.svg.png" style={{ width: 20, height: 20 }} alt="Spotify" />
        </div>
        <ul>
          <li><Link to="/lastfm/popular">popular</Link></li>
          <li><Link to="/lastfm/played">played</Link></li>
          <li><Link to="/lastfm/loved">loved</Link></li>
          <li><Link to="/lastfm/lost">lost</Link></li>
        </ul>
      </div>
    </div>
  );
}