/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />
import React from 'react';
import styled from 'styled-components';

const AlbumWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  > div {
  }
`;

const AlbumImage = styled.img`
  width: 100px;
  margin-right: 3px;
`;

const AlbumTitle = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: bold;
`;

const AlbumTracks = styled.div`
  margin-top: 5px;
`;


type AlbumProps = {
  album: SpotifyApi.AlbumObjectFull,
  tracks?: SpotifyApi.TrackObjectFull[],
}

export const AlbumSummary: React.FC<AlbumProps> = (props) => {
  const album = props.album;
  const tracks: SpotifyApi.TrackObjectFull[] | SpotifyApi.TrackObjectSimplified[] = props.tracks ? props.tracks : props.album.tracks.items;

  return (
    <AlbumWrapper>
      <AlbumImage src={album.images && album.images[2] ? album.images[1].url : undefined} />
      <div>
        <AlbumTitle href={album.external_urls.spotify} target="_blank">{album.name}</AlbumTitle>
        <AlbumTracks>
          {tracks.map(track => <div key={track.id} data-popularity={(track as SpotifyApi.TrackObjectFull).popularity}>{track.name}</div>)}
        </AlbumTracks>
      </div>
    </AlbumWrapper>
  );
}