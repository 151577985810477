/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />
import React from 'react';
import styled from 'styled-components';
import SpotifyAccess from '../common/spotify';

const TrackWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  a {
    color: #000;
    text-decoration: none;
  }
  a:hover {
    color: #555;
    text-decoration: underline;
  }
`;

const AlbumArt = styled.div`
  margin-right: 5px;
  img { 
    height: 45px;
    width: auto;
  }
`;

const TrackDetail = styled.div`
  flex-direction: column;
`;

const TrackTitle = styled.div`
  
`;

const TrackInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: .8em;
`;

type TrackProps = {
  track: SpotifyApi.TrackObjectFull
  accessToken?: string
}

export const Track: React.FC<TrackProps> = (props) => {
  const track = props.track;
  const accessToken = props.accessToken
  return (
    <TrackWrap data-id={track.id}>
      <AlbumArt onClick={() => { if(accessToken) SpotifyAccess.playSong(accessToken, track.uri) }}>
        <img src={track.album.images[1].url} alt="" />
      </AlbumArt>
      <TrackDetail>
        <TrackTitle>
          <a href={track.external_urls.spotify} target="_blank" rel="noopener noreferrer">{track.name}</a>
        </TrackTitle>
        <TrackInfo>
          <div><a href={track.artists[0].external_urls.spotify} target="_blank" rel="noopener noreferrer">{track.artists[0].name}</a> • {track.album.name}</div>
        </TrackInfo>
      </TrackDetail>
    </TrackWrap>
  );
}