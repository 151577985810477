/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />
import React from 'react';
import styled from 'styled-components';

type ArtistProps = {
  artist: SpotifyApi.ArtistObjectFull
}

export const Artist: React.FC<ArtistProps> = (props) => {
  const artist = props.artist;
  return (
    <div>
      {artist.images && artist.images[2] ? <img src={artist.images[2].url} width="150" /> : null}
      <a href={artist.external_urls.spotify} target="_blank">{artist.name}</a>
    </div>
  );
}