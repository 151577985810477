import React, { Component } from 'react';
import { UserContext } from '../userContext';

const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI || "http://localhost:3000/callback-spotify";
const SPOTIFY_SCOPES = process.env.REACT_APP_SPOTIFY_SCOPES?.split(",") || ['user-read-private', 'user-read-email', 'user-top-read', 'playlist-read-private', 'playlist-read-collaborative', 'playlist-modify-public', 'playlist-modify-private'];


export class Login extends Component<{}> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;
  
  logout = () => {
    localStorage.removeItem('SPOTIFY_ACCESS_TOKEN');
    this.context.setUserData({
      spotify: {
        authenticated: false,
        id: "",
        accessToken: "",
        profile: null
      }
    });
  }

  getLoginUrlSpotify(scopes: string[]): string {
    return 'https://accounts.spotify.com/authorize?client_id=' + SPOTIFY_CLIENT_ID +
      '&redirect_uri=' + encodeURIComponent(SPOTIFY_REDIRECT_URI) +
      '&scope=' + encodeURIComponent(scopes.join(' ')) +
      '&response_type=token';
  }

  render() {
    if (!this.context.userData.spotify.authenticated) {
      return (
        <div>
          <a href={this.getLoginUrlSpotify(SPOTIFY_SCOPES)}>Log me in</a>
        </div>
      )
    } else {
      return (<div onClick={this.logout}>logout {this.context.userData.spotify.profile?.display_name}</div>)
    }
  }
}