import React, { useContext, useState, useEffect } from 'react';
import SpotifyAccess from '../common/spotify';
import { UserContext } from '../userContext';
import { Track } from '../components/Track';

/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />



export const Tops: React.FC = () => {
  const { userData } = useContext(UserContext);
  const timeRanges = [{ timeRange: 'short_term', description: 'from past month' }, { timeRange: 'medium_term', description: 'from past 6 months' }, { timeRange: 'long_term', description: 'from past several years' }];
  const [timeRangeSelected, setTimeRangeSelected] = useState(timeRanges[0].timeRange);
  const limits = [20, 40, 50];
  const [limitSelected, setLimitSelected] = useState(limits[0]);
  const [playlistName, setPlaylistName] = useState("");
  const [tracks, setTracks] = useState<SpotifyApi.TrackObjectFull[]>([]);

  const computePlaylistName = (): string => {
    let name = "Top " + limitSelected;
    switch (timeRangeSelected) {
      case ('short_term'): name += ' Monthly'; break;
      case ('medium_term'): name += ' Half-Yearly'; break;
      case ('long_term'): name += ' All-Time'; break;
    }

    return name;
  };

  const createPlaylist = () => {
    SpotifyAccess.createPlaylist(userData.spotify.accessToken, userData.spotify.id, playlistName)
      .then((playlist) => {
        if (playlist) {
          return SpotifyAccess.addTracksToPlaylist(userData.spotify.accessToken, userData.spotify.id, playlist.id, tracks.map((t) => { return t.uri }));
        } else {
          throw new Error('Could not add tracks to playlist.');
        }
      }, (reason: any) => {
        console.log(reason);
      })
      .then(() => {
        console.log("Successfully created playlist.");
      });;
  };

  useEffect(() => {
    async function loadTracks() {
      const tracks = await SpotifyAccess.getTopTracks(userData.spotify.accessToken, timeRangeSelected, limitSelected);
      setTracks(tracks);
    }
    loadTracks();
    setPlaylistName(computePlaylistName());
  }, [timeRangeSelected, limitSelected]);

  return (<div>
    <select defaultValue={timeRangeSelected} onChange={(e) => { setTimeRangeSelected(e.currentTarget.value); }}>
      {timeRanges.map(({ timeRange, description }) => (
        <option key={timeRange} value={timeRange}>
          {description}
        </option>
      ))}
    </select>
    <select defaultValue={limitSelected} onChange={(e) => { setLimitSelected(parseInt(e.currentTarget.value)); }}>
      {limits.map((limit) => (
        <option key={limit} value={limit}>
          {limit}
        </option>
      ))}
    </select>
    <input value={playlistName} />
    <button type="button" onClick={createPlaylist}>create playlist</button>
    {tracks.map((track) => <Track key={track.id} track={track} />)}
  </div>);
}