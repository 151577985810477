import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

declare global {
  interface Window { analytics: any; }
}

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const trackPage = (page: string) => {
    window.analytics.page(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
}

export const identify = (id: string, traits: {} = {}) => {
  const hash = hashCode(id);
  window.analytics.identify(hash, traits);
}

function hashCode(s: string): number {
  var hash = 0, i = 0, len = s.length;
  while ( i < len ) {
      hash  = ((hash << 5) - hash + s.charCodeAt(i++)) << 0;
  }
  return hash;
}