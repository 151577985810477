import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { withTracker } from './components/util/tracker';
import { requireAuth } from './components/util/requireAuth';
import { UserContextProvider } from './userContext';
import { Home } from './pages/Home';
import { CallbackSpotify } from './components/CallbackSpotify';
import { Tops } from './pages/Tops';
import { ArtistSampler } from './pages/ArtistSampler';
import { Emotives } from './pages/Emotives';
import './App.css';

const App: React.FC = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />
          <Route exact path="/callback-spotify" component={CallbackSpotify} />
          <Route exact path="/tops" component={withTracker(requireAuth(Tops))} />
          <Route exact path="/artist-sampler" component={withTracker(requireAuth(ArtistSampler))} />
          <Route exact path="/emotives" component={withTracker(requireAuth(Emotives))} />
        </Switch>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;