import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import SpotifyAccess from '../common/spotify';
import { UserContext } from '../userContext';
import { Track } from '../components/Track';
import { TheSlider } from '../components/util/slider';

/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />

type TEmotive = 'valence' | 'danceability' | 'energy';
class Emotive {
  emotive: TEmotive;
  desc: string;
  constructor(emotive: TEmotive, desc?: string) {
    this.emotive = emotive;
    this.desc = desc || emotive;
  }
}

export const Emotives: React.FC = () => {
  const { userData } = useContext(UserContext);

  const [emotiveSelected, setEmotiveSelected] = useState<TEmotive>('valence');
  const emotives = [
    new Emotive('valence', 'positivity'),
    new Emotive('danceability'),
    new Emotive('energy'),
  ];
  const [strengths, setStrengths] = useState<readonly number[]>([25,75]);
  const [tracks, setTracks] = useState<SpotifyApi.TrackObjectFull[]>([]);
  const [audioFeatures, setAudioFeatures] = useState<SpotifyApi.AudioFeaturesObject[]>([]);
  const [filteredTracks, setFilteredTracks] = useState<SpotifyApi.TrackObjectFull[]>([]);

  useEffect(() => {
    async function getSavedTracks() {
      const accessToken = userData.spotify.accessToken;
      const userId = userData.spotify.id;
      const tracks = await SpotifyAccess.getTracksExhaustive(accessToken, userId);
      setTracks(tracks);
      const trackIds = tracks.map(t => t.id);
      console.log(trackIds);
      const features = await SpotifyAccess.getAudioFeatures(accessToken, trackIds);
      setAudioFeatures(features);
    }
    if (tracks.length === 0)
      getSavedTracks();
  }, [tracks, audioFeatures, emotiveSelected]);

  useEffect(() => {
    console.log(emotiveSelected, strengths);
    const trackIds = audioFeatures.filter(feature => feature[emotiveSelected] >= strengths[0]/100)
                                  .filter(feature => feature[emotiveSelected] <= strengths[1]/100)
                                  .map(f => f.id);
    console.log("filtered track count", trackIds.length);
    const filteredTracks = tracks.filter(track => trackIds.includes(track.id));
    setFilteredTracks(filteredTracks);
  }, [audioFeatures, strengths, emotiveSelected]);

  return (
    <div>
      <div>Emotive: <select defaultValue={emotiveSelected} onChange={(e) => { setEmotiveSelected(e.currentTarget.value as TEmotive); }}>
        {emotives.map(({ emotive, desc }) => (
          <option key={emotive} value={emotive}>
            {desc}
          </option>
        ))}
      </select>
        <TheSlider values={strengths} onChangeCallback={setStrengths} />
      </div>
      <div>Strengths: {strengths.map((strength) => (<span key={strength}>{strength}&nbsp;</span>))}</div>
      {filteredTracks.length > 0 ?
        <div>
          {filteredTracks.map(track => <Track key={track.id} accessToken={userData.spotify.accessToken} track={track} />)}
        </div>
        : null}
    </div>);
}