import React, { useState, createContext, Dispatch, SetStateAction } from 'react';
import { identify } from './components/util/tracker';
import SpotifyAccess from './common/spotify';
/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />

type UserData = {
  spotify: {
    authenticated: boolean;
    id: string;
    accessToken: string;
    profile: SpotifyApi.UserProfileResponse | null;
  }
}

type UserDataContext = {
  userData: UserData;
  setUserData: Dispatch<SetStateAction<UserData>>;
}

const initialState: UserDataContext = {
  userData: { spotify: { authenticated: false, id: "", accessToken: "", profile: null } },
  setUserData: () => { }
};

export const UserContext = createContext<UserDataContext>(initialState);

export const UserContextProvider: React.FC = (props) => {
  // initialize default user context
  const [userData, setUserData] = useState<UserData>({
    spotify: {
      authenticated: false, id: "", accessToken: "", profile: null
    }
  });

  const userDataContext = { userData, setUserData };

  // if we have an existing Spotify access token, handle it
  const accessTokenSpotify = localStorage.getItem('SPOTIFY_ACCESS_TOKEN');
  if (accessTokenSpotify && accessTokenSpotify.length > 0 && !userData.spotify.authenticated) {
    console.debug("Existing Spotify acccess token...", accessTokenSpotify);
    const spotifyAccess = new SpotifyAccess(accessTokenSpotify);
    spotifyAccess.getMe().then(profile => {
      setUserData({
        spotify: {
          authenticated: true,
          id: profile.id,
          accessToken: accessTokenSpotify,
          profile: profile
        }
      });
      identify(profile.id);
      console.debug("Retrieved Spotify profile.");
    }).catch(err => console.error);
  }

  return (
    <UserContext.Provider value={userDataContext}>
      {props.children}
    </UserContext.Provider>
  )
}