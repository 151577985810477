import React, { useState } from 'react';
import { render } from 'react-dom';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { Handle, Track } from './slider-components';

const sliderStyle: React.CSSProperties = {
    margin: '5%',
    position: 'relative',
    width: '90%'
};

const railStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: 14,
    borderRadius: 7,
    cursor: 'pointer',
    backgroundColor: 'rgb(155,155,155)'
};

const domain: ReadonlyArray<number> = [0, 100];

type SliderProps = {
    values: readonly number[],
    onChangeCallback: (x: readonly number[]) => any
}

export const TheSlider: React.FC<SliderProps> = (props) => {
    const [values, setValues] = useState<ReadonlyArray<number>>(props.values);
    const handleChange = (values: ReadonlyArray<number>): void => {
        setValues(values);
        props.onChangeCallback(values);
    };
    return (
        <div style={{ height: 120, width: '100%' }}>
            <Slider
                mode={1}
                step={1}
                domain={domain}
                rootStyle={sliderStyle}
                onChange={handleChange}
                values={values}
            >
                <Rail>
                    {({ getRailProps }) => (
                        <div style={railStyle} {...getRailProps()} />
                    )}
                </Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map(handle => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
        </div>
    )
}

// class Example extends React.Component {
//   public state = {
//     values: [25, 75] as ReadonlyArray<number>
//   };

//   public onChange = (values: number[]) => {
//     this.setState({ values });
//   };

//   public render() {
//     const {
//       state: { values }
//     } = this;

//     return (
//       <div style={{ height: 120, width: '100%' }}>
//         <Slider
//           mode={1}
//           step={1}
//           domain={domain}
//           rootStyle={sliderStyle}
//           onChange={this.onChange}
//           values={values}
//         >
//           <Rail>
//             {({ getRailProps }) => (
//               <div style={railStyle} {...getRailProps()} />
//             )}
//           </Rail>
//           <Handles>
//             {({ handles, getHandleProps }) => (
//               <div className="slider-handles">
//                 {handles.map(handle => (
//                   <Handle
//                     key={handle.id}
//                     handle={handle}
//                     domain={domain}
//                     getHandleProps={getHandleProps}
//                   />
//                 ))}
//               </div>
//             )}
//           </Handles>
//           <Tracks left={false} right={false}>
//             {({ tracks, getTrackProps }) => (
//               <div className="slider-tracks">
//                 {tracks.map(({ id, source, target }) => (
//                   <Track
//                     key={id}
//                     source={source}
//                     target={target}
//                     getTrackProps={getTrackProps}
//                   />
//                 ))}
//               </div>
//             )}
//           </Tracks>
//         </Slider>
//       </div>
//     );
//   }
// }

// render(<Example />, document.getElementById('root'));
