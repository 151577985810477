// tslint:disable:max-line-length
// tslint:disable-next-line:no-reference
/// <reference path="../../node_modules/@types/spotify-api/index.d.ts" />
import axios from 'axios';

const apiEndpoint: string = 'https://api.spotify.com/v1/';

export default class SpotifyAccess {
  private apiEndpoint: string = 'https://api.spotify.com/v1/';
  private accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
    console.debug("created instance of SpotifyAccess");
  }

  public static async getData<TReturn>(path: string, accessToken: string) : Promise<TReturn> {
    const url = !apiEndpoint.startsWith("https://") ? apiEndpoint + path : path;
    return axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    }).then((response) => {
      console.log(`getData @ ${url.split("/").pop()}`, response.status);
      const data = response.data as TReturn;
      return data;
    });
  }

  public getMe(): Promise<SpotifyApi.UserProfileResponse> {
    return axios.get(this.apiEndpoint + 'me', {
      headers: {
        Authorization: 'Bearer ' + this.accessToken,
      }
    }).then(response => {
      console.log('GetMe: ' + response.status);
      return response.data;
    });
  }

  public static async playSong(accessToken: string, songUri: string) {
    return axios.put(apiEndpoint + "me/player/play", {
      uris: [songUri]
    }, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    });
  }

  public static async getUserPlaylists(accessToken: string, userId?: string, isOwner: boolean = false, playlists: SpotifyApi.PlaylistObjectSimplified[] = [], url?: string): Promise<SpotifyApi.PlaylistObjectSimplified[]> {
    /**
     * Retrieves a list of playlists owned or saved by the current user, or by the provided user.
     * 
     * @param isOwner - If true, only returns playlists owned by the provided user.
     */
    if(!url) url = apiEndpoint + (userId ? `users/${userId}/playlists` : "me/playlists") + "?offset=0&limit=50";
    return SpotifyAccess.getData<SpotifyApi.ListOfUsersPlaylistsResponse>(url, accessToken)
                        .then(data => {
                          const playlistsResponse = (userId && isOwner) ? data.items.filter(playlist => playlist.owner.id === userId) : data.items;
                          playlists = playlists.concat(playlistsResponse);
                          if(!data.next)
                            return playlists;
                          return SpotifyAccess.getUserPlaylists(accessToken, userId, isOwner, playlists, data.next);
                        });
  }

  public static async getTracksExhaustive(accessToken: string, ownerUserId?: string): Promise<SpotifyApi.TrackObjectFull[]> {
    /**
     * Retrieves a user's saved tracks as well as all tracks from their saved playlists, returning a single de-duplicated list.
     */
    const playlists = await SpotifyAccess.getUserPlaylists(accessToken, ownerUserId, true);
    const playlistTracksRequests = playlists.map(playlist => SpotifyAccess.getPlaylistTracks(accessToken, playlist.id).then(result => result.map(playlist_track => playlist_track.track)));
    const savedTracks = SpotifyAccess.getTracksSaved(accessToken);
    return Promise.all([savedTracks, ...playlistTracksRequests]).then(results => {
      const allTracks = results.reduce((p, result) => p.concat(result), []);
      const uniqueTracks = Array.from(new Set(allTracks.map(t => t.id))).map(id => {
        const track = allTracks.find(t => t.id === id);
        if(track) return track
        else throw new TypeError("Could not find expected track.");
      });
      return uniqueTracks;
    });
  }

  public static async getTracksSaved(accessToken: string, limit: number = 50): Promise<SpotifyApi.TrackObjectFull[]> {
    return await SpotifyAccess.getTracksSavedPager(accessToken, []);
  }

  private static async getTracksSavedPager(accessToken: string, tracks: SpotifyApi.TrackObjectFull[], url?: string): Promise<SpotifyApi.TrackObjectFull[]> {
    if(!url) url = `${apiEndpoint}me/tracks?offset=0&limit=50`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      const data = response.data as SpotifyApi.UsersSavedTracksResponse;
      console.log('getSavedTracks: ' + response.status);
      tracks = tracks.concat(data.items.map((i: any) => i.track));
      if(!data.next)
        return tracks;
      return SpotifyAccess.getTracksSavedPager(accessToken, tracks, data.next);
    });
  }

  public static async getSavedAlbums(accessToken: string, limit: number = 50): Promise<SpotifyApi.AlbumObjectFull[]> {
    return axios.get(apiEndpoint + 'me/albums?offset=0&limit=' + limit, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('getSavedAlbums: ' + response.status);
      return response.data.items;
    });
  }

  public static async getTopTracks(accessToken: string, timeRange: string, limit: number): Promise<SpotifyApi.TrackObjectFull[]> {
    if (limit < 0 || limit > 100) {
      throw new Error('Invalid number of top tracks selected.');
    }

    return axios.get(apiEndpoint + 'me/top/tracks?time_range=' + timeRange + '&limit=' + limit, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('GetTopTracks: ' + response.status);
      return (response.data as SpotifyApi.PagingObject<SpotifyApi.TrackObjectFull>).items;
    });
  }

  public static async getPlaylistTracks(accessToken: string, playlistId: string): Promise<SpotifyApi.PlaylistTrackObject[]> {
    return axios.get(apiEndpoint + 'playlists/' + playlistId + '/tracks', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('GetPlaylistTracks: ' + response.status);
      return response.data.items;
    });
  }

  public static async getAlbumTracks(accessToken:string, albumId: string, tracks: SpotifyApi.TrackObjectSimplified[] = [], url?: string): Promise<SpotifyApi.TrackObjectSimplified[]> {
    if(!url) url = apiEndpoint + `albums/${albumId}/tracks`;
    return SpotifyAccess.getData<SpotifyApi.AlbumTracksResponse>(url, accessToken)
                        .then(response => {
                          tracks = tracks.concat(response.items);
                          if(response.next) 
                            return SpotifyAccess.getAlbumTracks(accessToken, albumId, tracks);
                          return tracks;
                        });
  }

  public static async createPlaylist(accessToken: string, userId: string, playlistName: string, description: string = '', namePrefix = 'smrtfy :: '): Promise<SpotifyApi.PlaylistObjectSimplified> {
    const today = new Date();
    const dateString = today.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
    const descriptionAppend = 'created by Smartifyly at https://smartify.ly on '+dateString;
    return axios.post(apiEndpoint + 'users/' + userId + '/playlists', {
      name: namePrefix + playlistName,
      public: false,
      description: ((description.length > 0) ? description + ' (' + descriptionAppend + ')' : descriptionAppend.charAt(0).toUpperCase() + descriptionAppend.slice(1)),
    }, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('CreatePlaylist: ' + response.status);
      return response.data;
    });
  }

  public static async addTracksToPlaylist(accessToken: string, userId: string, playlistId: string, trackUris: string[]): Promise<SpotifyApi.PlaylistSnapshotResponse> {
    return axios.post(apiEndpoint + 'users/' + userId + '/playlists/' + playlistId + '/tracks', {
      uris: trackUris,
    }, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('AddTracksToPlaylist: ' + response.status);
      return response.data;
    });
  }

  public static async searchArtists(accessToken: string, searchString: string): Promise<any[]> {
    return axios.get(apiEndpoint + 'search?q=' + searchString + '&type=artist&market=from_token&limit=20', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('SearchArtists: ' + response.status, response.data.artists);
      return response.data.artists.items;
    });
  }

  public searchTracksByArtist(accessToken: string, trackName: string, artistName: string): Promise<SpotifyApi.TrackObjectFull[]> {
    return axios.get(this.apiEndpoint + 'search?q=track:' + trackName + ' artist:' + artistName + '&type=track&market=from_token&limit=5', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then((response) => {
      console.log('SearchTracksByArtist(' + trackName + ', ' + artistName + '): ' + response.status);
      const trackSearchResponse = response.data as SpotifyApi.TrackSearchResponse;
      return trackSearchResponse.tracks.items;
    });
  }

  public static async getArtistTracks(accessToken: string, artistId: string) {
    return SpotifyAccess.getArtistAlbums(accessToken, artistId)
                        .then(albums => {
                          const albumTrackRequests = albums.map(album => SpotifyAccess.getAlbumTracks(accessToken, album.id));
                          return Promise.all(albumTrackRequests).then(responses => responses.reduce((pre, cur) => pre.concat(cur)))
                        });
  }

  public static async getArtistAlbums(accessToken: string, artistId: string, userCountry: string = "from_token", includeSinglesCompilations: boolean = false): Promise<SpotifyApi.AlbumObjectSimplified[]> {
    const albumResults: any[] = [];
    const limit: number = 50;
    return SpotifyAccess.getArtistAlbumsPager(accessToken, 0, limit, albumResults, userCountry, artistId, includeSinglesCompilations).then(response => {
      // console.log(response.data);
      console.log(response.data.items.length + ' === ' + response.data.total);
      if (response.data.items.length === response.data.total) {
        console.log(albumResults);
        return albumResults[0];
      }

      const i: number = 1;
      const offsets: number[] = [];
      // for (i = 1, i * limit < response.data.total; i++; ) {
      offsets.push(i * limit);
      // }
      console.log(offsets);
      return axios.all(offsets.map(offset => SpotifyAccess.getArtistAlbumsPager(accessToken, offset, limit, albumResults, userCountry, artistId, includeSinglesCompilations)))
                  .then(results => {
                    console.log(results);
                    console.log(albumResults);
                    return [].concat.apply([], albumResults);
                  });
    });
  }

  public static async getAlbums(accessToken: string, albumIds: Array<string>) : Promise<SpotifyApi.AlbumObjectFull[]> {
    const limit: number = 20;
    const albumIdsChunked = SpotifyAccess.chunkArray(albumIds, limit);
    return axios.all(albumIdsChunked.map(chunk => axios.get(apiEndpoint + 'albums?ids='+chunk.join(','), {
      headers: { Authorization: 'Bearer ' + accessToken}
    }))).then((responses) => {
      const albums: SpotifyApi.AlbumObjectFull[] = responses.map(response => response.data.albums).reduce((pre, cur) => pre.concat(cur));
      return albums;
    });
  }

  public getAlbumTracks(accessToken: string, albumId: string): Promise<any> {
    return axios.get(this.apiEndpoint + 'albums/' + albumId + '/tracks?market=from_token', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('GetAlbumTracks: ' + response.status, response.data);
      return response.data.items;
    });
  }

  public static async getTracks(accessToken: string, trackIds: string[]): Promise<SpotifyApi.TrackObjectFull[]> {
    const limit: number = 50;
    const trackIdsChunked = SpotifyAccess.chunkArray(trackIds, limit);
    return axios.all(trackIdsChunked.map(chunk => axios.get(apiEndpoint + 'tracks?ids='+chunk.join(','), {
      headers: { Authorization: 'Bearer ' + accessToken }
    }))).then((responses) => {
      const tracks: SpotifyApi.TrackObjectFull[] = responses.map(response => response.data.tracks).reduce((pre, cur) => pre.concat(cur));
      return tracks;
    });
  }

  public static async getAudioFeatures(accessToken: string, trackIds: Array<string>) : Promise<SpotifyApi.AudioFeaturesObject[]> {
    const limit: number = 100;
    const trackIdsChunked = SpotifyAccess.chunkArray(trackIds, limit);
    return axios.all(trackIdsChunked.map(chunk => axios.get(apiEndpoint + 'audio-features?ids='+chunk.join(','), {
      headers: { Authorization: 'Bearer ' + accessToken }
    }))).then((responses) => {
      const features: SpotifyApi.AudioFeaturesObject[] = responses.map(response => response.data.audio_features.filter((feature: any) => feature !== null)).reduce((pre, cur) => pre.concat(cur), []);
      return features;
    });
  }

  private static getArtistAlbumsPager(accessToken: string, offset: number, limit: number, albumResults: any[], userCountry: string, artistId: string, includeSinglesCompilations: boolean = false): Promise<any> {
    let url: string = apiEndpoint + 'artists/' + artistId + '/albums?market=' + userCountry + '&album_type=album';
    if (includeSinglesCompilations) {
      url += ',single,compliation';
    }
    url += '&offset=' + offset + '&limit=' + limit;

    return axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }).then(response => {
      console.log('GetArtistAlbumsPager: ' + response.status + ', offset: ' + offset);
      albumResults.push(response.data.items);
      return response;
    });
  }

  public static chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
  }
}
